import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import { formatNumber, inputField, loadingMessage } from './utilities';

ReactModal.setAppElement('#root');

function Sms() {
  const [modalOpen, setModalOpen] = useState(false);
  const [submitResponse, setSubmitResponse] = useState('');
  const [newMobileNumber, setNewMobileNumber] = useState('');
  const [newPatronName, setNewPatronName] = useState('');
  const [message, setMessage] = useState('');
  const [slider, setSlider] = useState(0);
  const [sliderClass, setSliderClass] = useState('');
  const [sliderDisabled, setSliderDisabled] = useState(true);
  const [alertClass, setAlertClass] = useState('');
  const [alertMessage, setAlertMessage] = useState('Drag To Send');
  const [allChecked, setAllChecked] = useState(false);
  const [patrons, setPatrons] = useState([]);
  const [editingPatron, setEditingPatron] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch('/.netlify/functions/api?table=patrons').then(response => {
      response.json().then(patronsResponse => {
        const parsed = patronsResponse.map(patron => {
          patron.isChecked = false;
          patron.editing = false;
          return patron;
        });
        setIsLoading(false);
        setPatrons(parsed.sort((a, b) => a.name.localeCompare(b.name)));
      });
    });
  }, [setIsLoading]);

  const handleTextAreaChange = event => {
    const { value } = event.target;
    setMessage(value);
    setSliderDisabled(!value.length);
  };

  const handlePatronCheckboxChange = event => {
    const { value } = event.target;
    setPatrons(patrons.map(patron => {
      if (patron.number === value) {
        patron.isChecked = !patron.isChecked;
      }
      return patron;
    }));
  };

  const handleAllCheckboxChange = () => {
    setAllChecked(!allChecked);
    let newSelection = patrons.map(patron => {
      patron.isChecked = !allChecked;
      return patron;
    });
    setPatrons(newSelection);
  };

  const sendMessage = () => {
    const selected = patrons.filter(patron => patron.isChecked);
    selected.forEach(patron => {
      fetch(`/.netlify/functions/sms`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ number: patron.number, message })
      }).then(response => {
        response.json().then(json => {
          const { to } = json.data.messageResponse;
          const patron = patrons.filter(patron => `+1${patron.number}` === to)[0];
          patron.isChecked = false;
          setPatrons([...patrons, patron]);
        });
      }).catch(error => {
        console.error(error);
      });
    });
  };

  const setSliderValue = event => {
    const { value } = event.target;
    if (slider === 0 && value > 1) {
      return;
    }
    setSlider(value);
    if (value < 1) {
      setSliderClass('');
      setAlertClass('');
      setAlertMessage('Drag To Send');
    } else if (value > 75) {
      setSliderClass('range-success');
      setAlertClass('alert-success');
      setAlertMessage('Release To Send');
    } else {
      setSliderClass('range-error');
      setAlertClass('alert-error');
      setAlertMessage('Continue Dragging To Send');
    }
  };

  const checkSliderValue = event => {
    const { value } = event.target;
    if (value > 75) {
      setSlider(100);
      setAlertClass('alert-success');
      setAlertMessage('Sending...');
      setSliderDisabled(true);
      sendMessage();
    } else {
      setSliderClass('');
      setSlider(0);
      setAlertClass('');
      setAlertMessage('Drag To Send');
    }
  };

  const editPatron = event => {
    const { name } = event.target;
    setPatrons(patrons.map(patron => {
      if (patron.id === name) {
        setEditingPatron(patron.id);
        return { ...patron, editing: true };
      }
      return patron;
    }));
  };

  const savePatron = event => {
    const { name } = event.target;
    setPatrons(patrons.map(patron => {
      if (patron.id === name) {
        setEditingPatron(0);
        return { ...patron, editing: false };
      }
      return patron;
    }));
    const patron = patrons.filter(patron => patron.id === name)[0];
    fetch(`/.netlify/functions/api?table=patrons&id=${patron.id}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: patron.name,
        number: patron.number
      })
    }).then(response => {
      console.log(response);
    });
  };

  const patronCellShouldDisplay = (isEditing, isEditCell = false) => {
    if (!isEditCell) {
      return isEditing ? 'hidden' : '';
    } else {
      return isEditing ? '' : 'hidden';
    }
  };

  const handleEditPatron = event => {
    const { name, value } = event.target;
    setPatrons(patrons.map(patron => {
      if (patron.id === editingPatron) {
        return { ...patron, [name]: value };
      }
      return patron;
    }));
  };

  const prettifyDate = dateString => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const generateRows = () => {
    return patrons.map((patron, index) => {
      return (
        <tr className="text-black" key={`key-${index}`}>
          <td width={30}>
            <label>
              <input type="checkbox" className="checkbox" checked={patron.isChecked} value={patron.number} onChange={handlePatronCheckboxChange} />
            </label>
          </td>
          <td className={patronCellShouldDisplay(patron.editing)}>{patron.name}</td>
          <td className={patronCellShouldDisplay(patron.editing, true)}><input className={inputField} type="text" name="name" value={patron.name} onChange={handleEditPatron} /></td>
          <td>{prettifyDate(patron.added)}</td>
          <td className={patronCellShouldDisplay(patron.editing)}>{patron.number}</td>
          <td className={patronCellShouldDisplay(patron.editing, true)}><input className={inputField} type="text" name="number" value={patron.number} onChange={handleEditPatron} /></td>
          <td width={30} className={patronCellShouldDisplay(patron.editing)}><button className="xs" onClick={editPatron} name={patron.id}>✏️</button></td>
          <td width={30} className={patronCellShouldDisplay(patron.editing, true)}><button className="xs" onClick={savePatron} name={patron.id}>💾</button></td>
        </tr>
      )
    });
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    document.body.style.overflow = 'unset';
  };

  const handleAdd = () => {
    if (newMobileNumber.length !== 12 || newPatronName.length < 2) {
      return false;
    }
    fetch('/.netlify/functions/api?table=patrons').then(response => {
      response.json().then(json => {
        let exists = false;
        json.forEach(item => {
          const {name, number} = item;
          if (name === newPatronName && number === newMobileNumber.replace(/-/g, '')) {
            exists = true;
          }
        });
        if (exists) {
          setSubmitResponse('Patron already exists!');
        } else {
          fetch('/.netlify/functions/api?table=patrons', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              name: newPatronName,
              number: newMobileNumber
            })
          }).then(response => {
            console.log('response', response);
            const { status } = response;
            if (status === 403) {
              setSubmitResponse('Patron already exists!');
            } else if (status === 200) {
              setSubmitResponse('Patron added!');
              const newPatrons = [ ...patrons, {
                name: newPatronName,
                number: newMobileNumber.replace(/-/g, ''),
                isChecked: false,
                editing: false
              }];
              setPatrons(newPatrons.sort((a, b) => a.name.localeCompare(b.name)));
              setTimeout(handleCloseModal, 1500);
            } else {
              setSubmitResponse('Oops, something went wrong!');
            }
          });
        }
      });
    });
  };

  const handleMobileNumberChange = event => {
    event.preventDefault();
    let { value } = event.target;
    if (value.length > 12) {
      return false;
    }
    setNewMobileNumber(formatNumber(value));
  };

  const handlePatronNameChange = event => {
    event.preventDefault();
    const { value } = event.target;
    console.log('vlaue', value);
    setNewPatronName(value);
  };

  const handleAddNewPatron = () => {
    setModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const generateForm = () => {
    if (submitResponse) {
      return (
        <div className="flex flex-col items-center my-4">{submitResponse}</div>
      );
    }

    return (
      <>
        <div className="flex flex-col items-center my-4">
          <input type="text" placeholder="Name" value={newPatronName} onChange={handlePatronNameChange} className="input input-bordered bg-black" />
        </div>
        <div className="flex flex-col items-center my-4">
          <input type="text" placeholder="(XXX)XXX-XXXX" value={newMobileNumber} onChange={handleMobileNumberChange} className="input input-bordered bg-black join-item" />
        </div>
        <div className="flex flex-col items-center my-4">
          <button onClick={handleAdd} className="btn btn-lg bg-black hover:bg-neutral-800">Add Patron</button>
        </div>
      </>
    );
  };

  if (isLoading) {
    return (
      <div className="main-container container mx-auto mt-8 p-1 sm:p-2 w-screen flex justify-center items-center text-5xl text-black">
        {loadingMessage}
      </div>
    );
  }

  return (
    <>
      <ReactModal isOpen={modalOpen} onRequestClose={handleCloseModal} style={{ overlay: { zIndex: 1000 } }}>
        <button type="button" onClick={handleCloseModal} className="absolute top-0 right-0 bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500">
          <span className="sr-only">Close Modal</span>
          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <div className="p-8 h-full">
          {generateForm()}
        </div>
      </ReactModal>
      <div className="container mx-auto p-4">
        <div>
          <textarea className="textarea textarea-lg textarea-ghost w-full" onChange={handleTextAreaChange} placeholder="Message..." value={message}></textarea>
          <div role="alert" className={`alert flex justify-center mb-4 p-2 ${alertClass}`}>
            <span>{alertMessage}</span>
          </div>
          <input disabled={sliderDisabled} className={`range range-lg ${sliderClass}`} type="range" min={0} max={100} value={slider} onChange={setSliderValue} onMouseUp={checkSliderValue} />
        </div>
        <div className="mt-4">
          <div className="overflow-x-auto">
            <button className="btn btn-accent w-full" onClick={handleAddNewPatron}>Add Patron</button>
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <label>
                      <input type="checkbox" className="checkbox" checked={allChecked} onChange={handleAllCheckboxChange} />
                    </label>
                  </th>
                  <th>Name</th>
                  <th>Date Added</th>
                  <th>Number</th>
                </tr>
              </thead>
              <tbody>
                {generateRows()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sms;
